<template>
    <div style="max-width:600px;">

        <v-snackbar
            v-model="alertVisible"
            :vertical="true"
            :timeout="-1"
            >
            <span class="white--text caption" style="font-size:10px !important;">
            {{ bannerTxt }}
                <v-btn v-if="!expand" x-small @click="readMore" text color="#EFE6DD">READ MORE</v-btn>
            </span>

            <v-row
                class="mt-3"
                align="center"
                no-gutters>
                <v-spacer></v-spacer>
                <v-col class="shrink">
                <v-btn
                        text
                        x-small
                        color="white"
                        @click="closeAlert">
                        CLOSE
                    </v-btn>
                </v-col>
                <v-col class="shrink">
                <v-btn
                        text
                        x-small
                        color="primary"
                        to="/PrivacyPolicy">
                        PRIVACY POLICY
                    </v-btn>
                </v-col>
            </v-row>

        </v-snackbar>


        
    </div>
</template>


<script>
import gtagAnalytics from '@/plugins/gtagAnalytics.js'

export default {
    data() {
        return {
            alertVisible: false,
            expand:false,
            bannerTxt: `This app or app developer is not associated with WhatsApp. WhatsApp is a registered...`

        }
    },
    methods: {
        readMore() {
            this.bannerTxt = `This app or app developer is not associated with WhatsApp. WhatsApp is a registered trademark of WhatsApp Inc. You should follow WhatsApp's terms and condition while sending messages through QuickWApp. This website uses cookies to improve your experience. Visit our Privacy Policy to learn more.`
            this.expand = true
        },
        closeAlert() {
            localStorage.setItem('privacypolicyalert', "false")
            this.alertVisible = false
            gtagAnalytics.sendEvent(this.$gtag, 'Close Privacy Policy Banner')
        }
    },
    created() {
        this.alertVisible = (localStorage.getItem('privacypolicyalert') == null || localStorage.getItem('privacypolicyalert') == 'true')
    }
}
</script>