<template>
    <div class="install-bnr-anim">
        <v-alert
            v-if="installApp && showAlert"
            color="grey darken-4"
            dark>
            <v-row align="center">
                <v-col cols="1" class="pa-0">
                    <v-btn
                        icon
                        small
                        @click="showAlert = false"
                        color="white">
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn> 
                </v-col>
                <v-col class="grow">
                    <!-- <div class="subtitle-2 primary--text">QuickWApp</div>
                    <div class="caption">Get our free app. It won't take up space on your device.</div> -->
                    <div class="caption font-weight-bold title-shine">Install QuickWApp</div>
                    <div class="caption font-weight-light white--text">Add to Home Screen, it uses almost no storage and provides a quick way to return to this app.</div>
                </v-col>
                <v-col class="shrink">
                    <v-btn
                        v-if="installApp"
                        class="primary--text text-capitalize"
                        rounded
                        small
                        color="white"
                        dark
                        @click="install">Install
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
    </div>

</template>


<script>
import gtagAnalytics from '@/plugins/gtagAnalytics.js' 

export default {
    data() {
        return {
            installApp: null,
            registration: null,
            showAlert: true
        }
    },
    methods: {
        async install() {
            gtagAnalytics.sendEvent(this.$gtag, 'InstallPromtPWA')
            this.installApp.prompt();
        },
        updateAvailable(event) {
            this.registration = event.detail
            gtagAnalytics.sendEvent(this.$gtag, 'UpdatePWA')
            this.refreshApp()
        },
        refreshApp() {
            // Make sure we only send a 'skip waiting' message if the SW is waiting
            if (!this.registration || !this.registration.waiting) return
            // Send message to SW to skip the waiting and activate the new SW
            this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
        },
    },
    created() {
        try{
            //Catch install event before prompt and save on 'installApp' variable
            window.addEventListener("beforeinstallprompt", e => {
                e.preventDefault();
                // Stash the event so it can be triggered later.
                this.installApp = e;
            });

            //Called when app installed
            window.addEventListener("appinstalled", () => {
                gtagAnalytics.sendEvent(this.$gtag, 'QuickWApp Installed')
                this.installApp = null;
            });


            //Event listener for update - run once and remove listener
            document.addEventListener('swUpdated', this.updateAvailable, { once: true })

            navigator.serviceWorker.addEventListener('controllerchange', () => {
                // We'll also need to add 'refreshing' to our data originally set to false.
                if (this.refreshing) return
                this.refreshing = true
                // Here the actual reload of the page occurs
                window.location.reload()
            })
        } catch(e) {
            console.log('e')
        }
        

    }
}

</script>

<style scoped>

.title-shine {
  color: #3FC051;
  background: linear-gradient(to right, #3FC051 0, white 1%, #3FC051 20%);
  background-position: 0;
  background-clip:text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  font-size: 10px;
  text-decoration: none;
  white-space: nowrap;
}
    
@keyframes shine {
  0%{
    background-position: 0;
  }
  60%{
    background-position: 180px;
}
  100%{
    background-position: 180px;
  }
}

.install-bnr-anim {
  position: relative;
  animation-name: install-bnr;
  animation-duration: 2s;
}


@keyframes install-bnr {
  0%   {left:-400px; top:0px;opacity:0;}
  100%  {left:0px; top:0px;opacity:1;}
}

</style>