<template>
    <div>
        <div class="mx-auto" style="max-width:800px;">
            <v-avatar class="mx-auto animated rotateIn" size="80">
                <img
                    src="https://quickwapp.com/img/logo.d18d4293.png">
            </v-avatar>
                
            <div class="fade-in" v-if="showLoading">
                <h3 class="mx-auto mt-2">Loading</h3>
                <div class="mx-auto mt-3 dot-pulse"></div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['showLoading'],
    data() {
        return {
            
        }
    }
}

</script>

<style scoped>

/* rotate animation */
@keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn;
}

.animated {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}


/* dot pulse animation */
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px; 
  -moz-border-radius: 5px;
  background-color: #3FC051;
  color: #3FC051;
  box-shadow: 9999px 0 0 -5px #3FC051;
  -moz-box-shadow:9999px 0 0 -5px #3FC051;
  -webkit-box-shadow: 9999px 0 0 -5px #3FC051;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: .25s;
}

.dot-pulse::before, .dot-pulse::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px; 
  -moz-border-radius: 5px;
  background-color: #3FC051;
  color: #3FC051;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #3FC051;
  -moz-box-shadow:9984px 0 0 -5px #3FC051;
  -webkit-box-shadow: 9984px 0 0 -5px #3FC051;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #3FC051;
  -moz-box-shadow:10014px 0 0 -5px #3FC051;
  -webkit-box-shadow: 10014px 0 0 -5px #3FC051;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: .5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #3FC051;
    -moz-box-shadow:9984px 0 0 -5px #3FC051;
    -webkit-box-shadow: 9984px 0 0 -5px #3FC051;
  }
  30% {
    box-shadow: 9984px 0 0 2px #3FC051;
    -moz-box-shadow:9984px 0 0 2px #3FC051;
    -webkit-box-shadow: 9984px 0 0 2px #3FC051;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #3FC051;
    -moz-box-shadow:9984px 0 0 -5px #3FC051;
    -webkit-box-shadow: 9984px 0 0 -5px #3FC051;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #3FC051;
    -moz-box-shadow:9999px 0 0 -5px #3FC051;
    -webkit-box-shadow: 9999px 0 0 -5px #3FC051;
  }
  30% {
    box-shadow: 9999px 0 0 2px #3FC051;
    -moz-box-shadow:9999px 0 0 2px #3FC051;
    -webkit-box-shadow: 9999px 0 0 2px #3FC051;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #3FC051;
    -moz-box-shadow:9999px 0 0 -5px #3FC051;
    -webkit-box-shadow: 9999px 0 0 -5px #3FC051;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #3FC051;
    -moz-box-shadow:10014px 0 0 -5px #3FC051;
    -webkit-box-shadow: 10014px 0 0 -5px #3FC051;
  }
  30% {
    box-shadow: 10014px 0 0 2px #3FC051;
    -moz-box-shadow:10014px 0 0 2px #3FC051;
    -webkit-box-shadow: 10014px 0 0 2px #3FC051;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #3FC051;
    -moz-box-shadow:10014px 0 0 -5px #3FC051;
    -webkit-box-shadow: 10014px 0 0 -5px #3FC051;
  }
}

/* Fade in animation */

.fade-in {
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}


</style>