<template>
    <div>
        <v-card
            class="mx-auto pa-3"
            color="grey darken-4"
            max-width="500">

            <v-row>
                <v-col cols="2">
                    <v-btn
                        class="text-left primary--text text-capitalize"
                        color="white"
                        @click="showChat"
                        small>
                        <v-icon small left>
                            mdi-arrow-left
                        </v-icon>
                        Back
                    </v-btn> 
                </v-col>
                <v-col cols="8">
                    <div class="text-center font-weight-medium orange--text">Generate Link</div>
                </v-col>
                <v-col cols="2">
                    <v-tooltip bottom color="black">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                color="white"
                                dark
                                small
                                right
                                v-bind="attrs"
                                v-on="on">
                                mdi-help-circle-outline
                            </v-icon>
                        </template>
                        <span class="orange--text">Generate a personalized WhatsApp link with a predefined message</span>
                    </v-tooltip>
                </v-col>
            </v-row>
            
            <vue-tel-input 
                class="mt-3" 
                ref="gentel"  
                v-model="phone" 
                style="background-color:white;">
            </vue-tel-input>
            
            <div class="mt-6 mb-6" v-if="!addMsg">
                <v-row justify="center" align="center">
                    <span class="caption white--text">Do you want to add a message?</span>
                    <v-btn 
                        class="text-capitalized" 
                        x-small 
                        text 
                        @click="addMsg = true"
                        color="orange darken-3">Click Here</v-btn>
                </v-row>
            </div>
            <v-textarea
                v-else
                class="mt-3 txt-area"
                background-color="white"
                outlined
                hide-details="true"
                rows="4"
                label="Message"
                v-model="messageToSend"
                prepend-inner-icon="mdi-message-text-outline"
                value="">
            </v-textarea>
            <v-btn
                class="mt-3 white--text"
                color="orange darken-3"
                @click="generateLink"
                :disabled="(phone.length > 0) ? false : true"
                id="disabled-get-link-btn"
                block>
                Get Link
                <v-icon right>
                    mdi-link-variant
                </v-icon>
            </v-btn>

            <template v-if="linkReady">
                <v-divider class="mt-4" dark/>
                <v-row class="mx-1 mb-2 mt-1" justify="center">
                    <v-col class="pa-0 mt-2" cols="8">
                        <v-text-field
                            label="Your Link"
                            color="primary"
                            class="mt-4 white--text"
                            background-color="black"
                            filled
                            dense
                            dark
                            focused
                            outlined
                            hide-details="true"
                            v-model="link"
                            prepend-inner-icon="mdi-link-variant"
                            ref="yourLink"
                            readonly>
                        </v-text-field>
                    </v-col>
                    <v-col class="pa-0 mt-2" cols="4">
                        <v-btn
                            class="mt-4 white--text"
                            @click="copyToClipboard"
                            color="primary">
                            <v-icon left>
                                mdi-content-copy
                            </v-icon>COPY
                        </v-btn>
                        
                    </v-col>
                </v-row>
                
            </template>
           
        </v-card>

        <v-snackbar
            v-model="snackbar"
            content-class="mx-auto text-center"
            width="3"
            color="primary"
            timeout="2000">
            <v-icon left>
                mdi-check-circle 
            </v-icon>
            Copied!
        </v-snackbar>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import gtagAnalytics from '@/plugins/gtagAnalytics.js' 

export default {
    data() {
        return {
            phone: '',
            messageToSend: '',
            linkReady: false,
            link:'',
            snackbar: false,
            addMsg: false
        }
    },
    computed: {
        
    },
    methods: {
        ...mapActions('storeApp', ['setChat']),
        showChat() {
            this.setChat(true)
        },
        generateLink() {
            let tel = this.$refs.gentel
            let phone = tel.phone.replace(/-/g,'')
            let countryCode = '+' + tel.activeCountry.dialCode
            let msgToSend = this.messageToSend
            msgToSend = msgToSend.replaceAll(String.fromCharCode(10), '%0a')

            if(msgToSend.length) {
                this.link = `https://api.whatsapp.com/send?phone=${countryCode}${phone}&text=${msgToSend}`
                gtagAnalytics.sendEvent(this.$gtag, 'GenerateLink-WA-API')
            } else {
                let encPhone = window.btoa(`${countryCode}${phone}`)
                this.link = `https://QuickWApp.com/qwar/${encPhone}`
                gtagAnalytics.sendEvent(this.$gtag, 'GenerateLink-QWAR')
            }

            this.linkReady = true
            gtagAnalytics.sendEvent(this.$gtag, 'GenerateLink')
        },
        copyToClipboard() {
            navigator.clipboard.writeText(this.link)
            this.snackbar = true
            this.$refs.yourLink.$refs.input.focus()
            gtagAnalytics.sendEvent(this.$gtag, 'GenerateLink-Copied')
        },
    },
}

</script>

<style scoped>

.txt-area {
    white-space: pre-wrap; 
    word-wrap: break-word;
    font-family: inherit;
}

#disabled-get-link-btn.v-btn--disabled {
    background-color: #EF6C00 !important;
    opacity:0.7;
}

</style>