<template>
  <v-app>
      <v-main>
        <router-view/>
      </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  metaInfo() {
    return { 
      title: "QuickWApp",
      meta: [
          { name: 'description', content:  'Send WhatsApp messages to any numbers without saving them in your contacts, use WhatsApp Web & Mobile.'},
          { property: 'og:title', content: "QuickWApp"},
          { property: 'og:site_name', content: 'https://quickwapp.com'},
          {property: 'og:type', content: 'website'},
          { vmid: 'og:image', property:'og:image', itemprop:'image', content:'https://quickwapp.com/img/logo.d18d4293.png'},
          {name: 'robots', content: 'index,follow'} 
      ]
    }
  },
  data: () => ({
    //
  }),
};
</script>

