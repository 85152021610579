<template>
    <div>
        <v-card
            class="mx-auto pa-3"
            color="#EFE6DD"
            max-width="500">
            <v-text-field
                v-if="addHistory"
                background-color="white"
                outlined
                dense
                hide-details="true"
                label="Name"
                :rules="rules"
                maxlength="25"
                v-model="name"
                prepend-inner-icon="mdi-account-circle"
            ></v-text-field>
            <vue-tel-input 
                class="mt-3" 
                ref="tel"  
                v-model="phone" 
                style="background-color:white;">
            </vue-tel-input>
            <v-select
                class="mt-3"
                :items="actionsList"
                background-color="white"
                hide-details="true"
                label="Select action"
                v-model="selected"
                dense
                outlined>
            </v-select>
            <v-textarea
                v-if="selected == 'Message'"
                class="mt-3 txt-area"
                background-color="white"
                outlined
                hide-details="true"
                rows="4"
                label="Message"
                v-model="messageToSend"
                prepend-inner-icon="mdi-message-text-outline"
                value="">
            </v-textarea>
            <v-btn
                class="mt-3"
                color="primary"
                @click="sendMessage"
                :disabled="(phone.length > 0) ? false : true"
                id="disabled-send-btn"
                block>
                SEND
                <v-icon right>
                    mdi-send
                </v-icon>
            </v-btn>
            <!-- <v-btn
                class="mt-3 white--text text-capitalize"
                color="amber darken-3"
                @click="createLink"
                block>
                Customize Your Page
                <v-icon right>
                    mdi-link-variant-plus
                </v-icon>
            </v-btn> -->
            <div class="mt-3">
                <v-row>
                    <v-col cols="7">
                        <v-btn
                            class="white--text text-capitalize"
                            small
                            color="orange darken-3"
                            @click="generateLink">Generate Link
                            <v-icon small right>
                                mdi-link-variant
                            </v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="1"/>
                    <v-col cols="4" class="d-flex justify-end pr-4">
                        <!-- <span class="caption font-weight-medium mt-1">Settings</span> -->
                        <v-btn
                            icon
                            small
                            @click="showHideSettings = !showHideSettings" 
                            color="grey darken-3">
                            <v-icon left right>
                                mdi-cog
                            </v-icon>
                        </v-btn> 
                    </v-col>
                </v-row>
            </div>
            <div class="mt-n6" v-if="showHideSettings">
                <v-divider class="mt-8"/>
                <v-row class="pa-0" align="center">
                    <v-col
                        cols="2" class="pr-0">
                        <v-switch
                            class="mb-4"
                            v-model="addHistoryData"
                            color="success"
                            hide-details>
                        </v-switch>
                    </v-col>
                    <v-col cols="7" class="pl-1 pr-0">
                        <span class="caption">Add this number to history</span>
                    </v-col>
                    <v-col cols="1" class="pl-0">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    color="grey"
                                    dark
                                    small
                                    v-bind="attrs"
                                    v-on="on">
                                    mdi-help-circle-outline
                                </v-icon>
                            </template>
                            <span>Turn on if you want to save the phone number in the History list below</span>
                        </v-tooltip>
                    </v-col>
                    <v-col cols="2">
                    </v-col>
                </v-row>
                <v-row class="mt-n12 pa-0" align="center">
                    <v-col
                        cols="2" class="pr-0">
                        <v-switch
                            class="mb-4"
                            v-model="whatsAppWeb"
                            color="success"
                            hide-details>
                        </v-switch>
                    </v-col>
                    <v-col cols="7" class="pl-1 pr-0">
                        <span class="caption">Use WhatsApp Web</span>
                    </v-col>
                    <v-col cols="1" class="pl-0">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    color="grey"
                                    dark
                                    small
                                    v-bind="attrs"
                                    v-on="on">
                                    mdi-help-circle-outline
                                </v-icon>
                            </template>
                            <span>Turn on if you want to use WhatsApp Web</span>
                        </v-tooltip>
                    </v-col>
                    <v-col cols="2">
                    </v-col>
                </v-row>
            </div>
            <!-- <div class="mt-4 text-center">
                <v-btn
                    class="primary--text text-capitalize"
                    color="white"
                    @click="shareQuickWApp"
                    small>
                    SHARE
                </v-btn>
                <span class="caption ml-2"><strong>QuickWApp</strong> with your friends</span>
            </div> -->
        </v-card>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import gtagAnalytics from '@/plugins/gtagAnalytics.js' 

export default {
    data() {
        return {
            name: '',
            phone: '',
            actionsList: ['Open chat','Message'],
            selected: 'Open chat',
            messageToSend: '',
            linkGen: '',
            showHideSettings: false,
            rules: [v => v.length <= 25 || 'Max 25 characters'],
        }
    },
    computed: {
        ...mapGetters('storeHistory', ['web', 'addHistory']),
        whatsAppWeb: {
            set(value) {
                this.setWeb(value)
            },
            get() {
                return this.web
            }
        },
        addHistoryData: {
            set(value) {
                this.setAddHistory(value)
            },
            get() {
                return this.addHistory
            }
        }
    },
    methods: {
        ...mapActions('storeHistory', ['addContact','setWeb', 'setAddHistory']),
        ...mapActions('storeApp', ['setChat']),
        sendMessage() {
            let tel = this.$refs.tel
            let phone = tel.phone.replace(/-/g,'')
            let countryCode = '+' + tel.activeCountry.dialCode
            let msgToSend = this.messageToSend
            msgToSend = msgToSend.replaceAll(String.fromCharCode(10), '%0a')
            if(this.selected == 'Open chat') {
                msgToSend = ''
            }

            if(this.addHistory) {
                gtagAnalytics.sendEvent(this.$gtag, 'SaveContactInHistory')
                let contactToSave = {
                    id: Date.now(),
                    name: this.name,
                    phone: countryCode + phone
                }
                this.addContact(contactToSave)
            }

            if(phone.length) {
                gtagAnalytics.sendEvent(this.$gtag, 'SendMsgClick')
                if(this.whatsAppWeb) {
                    gtagAnalytics.sendEvent(this.$gtag, 'SendMsgWA-Web')
                    window.open('https://web.whatsapp.com/send?phone=' + countryCode + phone + '&text=' + msgToSend, '_blank') 
                } else {
                    gtagAnalytics.sendEvent(this.$gtag, 'SendMsgWA-Mobile')
                    window.open('whatsapp://send?phone=' + countryCode + phone + '&text=' + msgToSend, '_blank')
                }
            }else {
                gtagAnalytics.sendEvent(this.$gtag, 'SendMsgClick-WITHOUT-PHONE-NUMBER')
                if(this.whatsAppWeb) {
                    gtagAnalytics.sendEvent(this.$gtag, 'SendMsgClick-WA-Web-WITHOUT-PHONE-NUMBER')
                    window.open('https://web.whatsapp.com/send?text=' + msgToSend, '_blank')
                } else {
                    gtagAnalytics.sendEvent(this.$gtag, 'SendMsgClick-WA-Mobile-WITHOUT-PHONE-NUMBER')
                    window.open('whatsapp://send?text=' + msgToSend, '_blank')
                }
            }
        },
        // createLink() {
        //     this.$router.push('/createlink')
        // },
        generateLink() {
            this.setChat(false)
            gtagAnalytics.sendEvent(this.$gtag, 'GenerateBtnClick')
        },
        joinQuickWAppTelegram() {
            window.open('https://t.me/quickwapp', '_blank')
        }
    }
}

</script>

<style scoped>

.txt-area {
    white-space: pre-wrap; 
    word-wrap: break-word;
    font-family: inherit;
}

#disabled-send-btn.v-btn--disabled {
    background-color: #3FC051 !important;
    opacity:0.7;
}

</style>