<template>
    <div>
        <v-app-bar
            fixed
            color="primary">
             <v-btn 
                icon
                to="/">
                <v-icon 
                    color="white" medium>mdi-arrow-left</v-icon>
            </v-btn>
            <v-img
                class="mx-1"
                src="@/assets/logo.png"
                max-width="30"
                max-height="30"
                contain>
            </v-img> 
            <v-toolbar-title class="white--text ml-2">
                QuickWApp
            </v-toolbar-title>

           

            
        </v-app-bar>
    </div>

</template>


<script>

export default {
    
}

</script>