<template>
    <div class="mt-4">
        <v-card
            class="mx-auto"
            max-width="400" 
            style="background:white;">

            <div class="px-2 mt-1 text-right">
                <span style="font-size:10px;">{{ createdTime }}</span>
            </div>

            <v-row
                class="px-2"
                no-gutters
                align="center"
                justify="space-between">

                <span>
                    <v-icon
                        color="grey darken-4"
                        medium
                        left>mdi-account-circle
                    </v-icon>
                    <span class="subtitle-2 font-weight-medium">{{ contact.name.length ? contact.name : 'CONTACT-NAME' }}</span>
                </span>

                <span class="subtitle-2 font-weight-medium">{{ contact.phone }}</span>

            </v-row>
            
            <v-divider class="mt-2 mx-1"/>

            <v-list-item class="grow">
                <v-row
                    class="px-1"
                    align="center"
                    justify="space-between">
                    <v-btn
                        icon
                        @click="deleteDialog = true"
                        color="black"
                        medium>
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                        icon
                        @click="editDialog = true"
                        color="black"
                        medium>
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                        icon
                        @click="save"
                        color="black"
                        medium>
                        <v-icon>mdi-account-box</v-icon>
                    </v-btn>
                    <v-btn
                        icon
                        @click="call"
                        color="black"
                        medium>
                        <v-icon>mdi-phone</v-icon>
                    </v-btn>
                    
                    <v-btn
                        small
                        @click="send" 
                        color="primary">SEND
                        <v-icon right>
                            mdi-send
                        </v-icon>
                    </v-btn>
                </v-row>
            </v-list-item>
            
        </v-card>

        <v-dialog
            v-model="deleteDialog"
            persistent
            max-width="300">
      
            <v-card>
                <v-card-title class="subtitle-2 font-weight-medium">
                Are you sure you want to delete {{ contact.name }}?
                </v-card-title>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="grey darken-1"
                        text
                        @click="deleteDialog = false">
                        CANCEL
                    </v-btn>
                    <v-btn
                        color="red darken-1"
                        text
                        @click="deleteItem">
                        DELETE
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog
            v-model="editDialog"
            persistent
            max-width="300px"
            height="600px">
      
            <v-card>
                <v-card-title>
                    <v-container>
                        <v-row>
                            <v-col
                                cols="12"
                                sm="12"
                                md="12">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details="true"
                                    :label="contact.name"
                                    v-model="nameNew"
                                    :rules="rules"
                                    maxlength="25"
                                    required>
                                </v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="12"
                                md="12">
                                <!-- <v-text-field
                                    outlined
                                    dense
                                    hide-details="true"
                                    :rules="rules"
                                    maxlength="25"
                                    :label="contact.phone"
                                    type="tel"
                                    v-model="phoneNew"
                                    required>
                                </v-text-field> -->
                                <vue-tel-input 
                                    class="mt-3" 
                                    ref="teledit"  
                                    v-model="phoneNew" 
                                    style="background-color:white;">
                                </vue-tel-input>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-title>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="grey darken-1"
                        text
                        @click="editDialog = false">
                        Close
                    </v-btn>
                    <v-btn
                        color="primary darken-1"
                        text
                        @click="edit">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


    </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
import gtagAnalytics from '@/plugins/gtagAnalytics.js' 

export default {
    props: ['contact'],
    data() {
        return {
            deleteDialog:false,
            editDialog: false,
            nameNew: '',
            phoneNew: '',
            rules: [v => v.length <= 25 || 'Max 25 characters'],
        }
    },
    computed: {
        ...mapGetters('storeHistory', ['web']),
        createdTime() {
            let dateTime = new Date(this.contact.id)
            let dateTimeStr = dateTime.getDate() +
                "/" + (dateTime.getMonth()+1) +
                "/" + dateTime.getFullYear() +
                " " + ((dateTime.getHours() < 10) ? '0' + dateTime.getHours() : dateTime.getHours()) +
                ":" + ((dateTime.getMinutes() < 10) ? '0' + dateTime.getMinutes() : dateTime.getMinutes()) +
                ":" + ((dateTime.getSeconds() < 10) ? '0' + dateTime.getSeconds() : dateTime.getSeconds())

            return dateTimeStr
        }
    },
    methods: {
        ...mapActions('storeHistory', ['deleteContact', 'editContact']),
        deleteItem() {
            gtagAnalytics.sendEvent(this.$gtag, 'DeleteContactFromHistory')
            this.dialog = false
            this.deleteContact(this.contact.id)
        },
        edit() {
            gtagAnalytics.sendEvent(this.$gtag, 'EditContactInHistory')
            let editedContact = {
                id: this.contact.id,
                name: (this.nameNew.length > 0) ? this.nameNew : this.contact.name,
                phone: (this.phoneNew.length > 0) ? this.getConvertedPhone() : this.contact.phone
            }
            this.editContact(editedContact)
            this.editDialog = false
        },
        getConvertedPhone() {
            let teledit = this.$refs.teledit
            let phone = teledit.phone.replace(/-/g,'')
            let countryCode = '+' + teledit.activeCountry.dialCode
            return countryCode + phone
        },
        save() {
            gtagAnalytics.sendEvent(this.$gtag, 'SaveContactFromHistory')
            const type="text/x-vcard"
            const a = document.createElement("a")
            a.style.display = "none";
            document.body.appendChild(a)

            a.href = window.URL.createObjectURL(
                new Blob(['BEGIN:VCARD\nVERSION:3.0\nFN;CHARSET=UTF-8:' + 
                this.contact.name + '\nN;CHARSET=UTF-8:;' + this.contact.name + 
                ';;;\nTEL;TYPE=CELL:' + 
                this.contact.phone +
                '\nREV:2020-10-12T13:58:30.004Z\nEND:VCARD'], { type })
            )

            a.setAttribute("download", this.contact.name + '.vcf')

            a.click()

            window.URL.revokeObjectURL(a.href)
            document.body.removeChild(a) 
        },
        call() {
            gtagAnalytics.sendEvent(this.$gtag, 'CallContactFromHistory')
            window.open('tel:' + this.contact.phone,'_blank')
        },
        send() {
            gtagAnalytics.sendEvent(this.$gtag, 'SendMsgHistoryClick')
            if(this.web) {
                gtagAnalytics.sendEvent(this.$gtag, 'SendMsgHistoryWA-Web')
                window.open('https://web.whatsapp.com/send?phone=' + this.contact.phone, '_blank') 
            } else {
                gtagAnalytics.sendEvent(this.$gtag, 'SendMsgHistoryWA-Mobile')
                window.open('whatsapp://send?phone=' + this.contact.phone, '_blank')
            }
        }

    }
    
}

</script>