<template>
    <div style="max-width:600px;">

       <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header style="background:#EFE6DD;">
                    <span class="font-weight-medium">History</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                   <v-list
                        style="max-height: 300px;width:100%;"
                        class="background overflow-y-auto px-2">

                        <div v-for="item in history" :key="item.id">
                            <HistoryContact :contact="item"/>
                        </div>

                    </v-list>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HistoryContact from '@/components/Chat/HistoryContact.vue'

export default {
    data() {
        return {
            
        }
    },
    computed: {
        ...mapGetters('storeHistory', ['history', 'addHistory'])
    },
    components: {
        HistoryContact
    }
}

</script>


<style scoped>

.background {
  background: #EFE6DD;
  height: 350px;  
  width:100%;
}

.v-expansion-panel-content>>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

</style>