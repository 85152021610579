<template>
    <div>
        <div class="mt-1 mb-6 text-center">
            <span class="caption" style="font-size:10px !important;">Powered by</span>
            <v-row class="mx-auto mt-1" justify="center" align="center">
            
            <v-img
                @click="homePage" 
                class="mr-1 img-cursor"
                src="@/assets/logo_small.png"
                max-width="20"
                max-height="20"
                contain>
            </v-img>
            
            <span class="caption font-weight-medium" style="font-size:10px !important;" @click="homePage"> QuickWApp.com</span>
            </v-row>
        </div>

    </div>    
</template>


<script>
import gtagAnalytics from '@/plugins/gtagAnalytics.js'

export default {
    methods: {
        homePage() {
            gtagAnalytics.sendEvent(this.$gtag, 'Home-FromFooter')
            this.$router.push('/')
        }
    }
}

</script>

<style scoped>

.img-cursor {
    cursor: pointer;
}


</style>